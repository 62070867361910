import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/Features';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
// import Newsletter from '../partials/Newsletter';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome />

        <section className="relative">

          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-12 md:py-20">

              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h2 className="h2 mb-4">There exists <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">a better replacement</span> for every expensive tool you are using.</h2>
                <p className="text-xl text-gray-600">Our team knows the real prices, alternatives, negotiated deals and migration process.</p>
              </div>

              <div className="mx-auto pb-12 md:pb-20">
                <div className="shadow-md sm:rounded-lg">
                <table className="max-w-full mx-auto">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th className="py-3 px-6 text-s font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">App</th>
                      <th className="py-3 px-6 text-s font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">Cost per month</th>
                      <th className="py-3 px-6 text-s font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">Savings per month</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-white">Notion</td>
                      <td className="py-4 px-6 text-s text-gray-500 whitespace-nowrap dark:text-gray-400">$490</td>
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">$120</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-white">Zapier</td>
                      <td className="py-4 px-6 text-s text-gray-500 whitespace-nowrap dark:text-gray-400">$690</td>
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">$360</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-white">Looker</td>
                      <td className="py-4 px-6 text-s text-gray-500 whitespace-nowrap dark:text-gray-400">$340</td>
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">$220</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-white">1password</td>
                      <td className="py-4 px-6 text-s text-gray-500 whitespace-nowrap dark:text-gray-400">$95</td>
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">$35</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-white">Slack</td>
                      <td className="py-4 px-6 text-s text-gray-500 whitespace-nowrap dark:text-gray-400">$940</td>
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">$315</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-white">Klavio</td>
                      <td className="py-4 px-6 text-s text-gray-500 whitespace-nowrap dark:text-gray-400">$490</td>
                      <td className="py-4 px-6 text-s font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">$160</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>

            </div>

          </div>

        </section>

        <FeaturesHome />
        <FeaturesBlocks />
        <Testimonials />
        <Cta />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;